const TICK_INTERVAL = 1000 / 60; // Targeting 60 TPS
const tickHandlers = [];

function addTickHandler(handler) {
    if (handler) tickHandlers.push(handler);
}
window.addTickHandler = addTickHandler;

function lerp(start, end, progress) {
    return start + progress * (end - start);
}

const EntratiRoot = document.getElementById('doktorentrati');
if (EntratiRoot) {
    const EntratiEyeRoot = EntratiRoot.querySelector('.EntratiEye');
    if (EntratiEyeRoot) {
        const Iris = EntratiEyeRoot.querySelector('.EntratiEye-iris');

        const INACTIVITY_DELAY = 3000;
        const RESET_INTERVAL = 2000;
        const REST_X = -50;
        const REST_Y = -55;
        let lastX = REST_X;
        let lastY = REST_Y;
        let lastActivity = 0;

        function setIrisPosition(x, y) {
            Iris.style.transform = `translate(${x}%, ${y}%)`;
        }
        function mouseHandler(e) {
            const mx = e.clientX;
            const my = e.clientY;
            const rect = EntratiEyeRoot.getBoundingClientRect();
            const cx = rect.left + rect.width / 2;
            const cy = rect.top + rect.height / 2;
            const dx = mx - cx;
            const dy = my - cy;

            const px = -50 + 10 * dx / (window.innerWidth / 2);
            const py = -50 + 10 * dy / (window.innerHeight / 2);

            lastX = px;
            lastY = py;
            lastActivity = Date.now();
            setIrisPosition(px, py);
        }

        function tickHandler(timestamp) {
            if (EntratiRoot.classList.contains('is-disabled')) return;

            const elapsed = timestamp - lastActivity;
            if (elapsed > INACTIVITY_DELAY) {
                const duration = elapsed - INACTIVITY_DELAY;

                let x = REST_X;
                let y = REST_Y;
                if (duration < RESET_INTERVAL) {
                    const progress = duration / RESET_INTERVAL;
                    x = lerp(lastX, REST_X, progress);
                    y = lerp(lastY, REST_Y, progress);
                    setIrisPosition(x, y);
                }
            }
        }
        addTickHandler(tickHandler);

        EntratiRoot.addEventListener('mousemove', mouseHandler);
    }
}

// Start ticker
function masterTick() {
    requestAnimationFrame(masterTickInternal);
}
let lastTickTimestamp = 0;
function masterTickInternal(timestamp) {
    if (timestamp === lastTickTimestamp) return;
    lastTickTimestamp = timestamp;

    const now = Date.now();
    for (const handler of tickHandlers) {
        handler(now);
    }
}
document.addEventListener('DOMContentLoaded', () => {
    setInterval(masterTick, TICK_INTERVAL);
});
